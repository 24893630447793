<template>
  <create-confirm />
</template>

<script>
import CreateConfirm from '@/components/pages/order_quest/CreateConfirm';

export default {
  name: 'EditConfirm',
  components: { CreateConfirm }
};
</script>
